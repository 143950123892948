
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as activateR5GLBF5yJeMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/auth/activate.vue?macro=true";
import { default as indexvuxJvdjoReMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/auth/index.vue?macro=true";
import { default as register_45completeGYztYoAaFsMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/auth/register-complete.vue?macro=true";
import { default as registerNok2vMTxVvMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/auth/register.vue?macro=true";
import { default as restoresZSiS0LQKEMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/auth/restore.vue?macro=true";
import { default as indexccomfGrXFIMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/buzzer/index.vue?macro=true";
import { default as _91id_93VbZ1n3cva1Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/dashboards/[id].vue?macro=true";
import { default as indexeWMwo4uklHMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/dashboards/index.vue?macro=true";
import { default as _91token_93afQtLerEEDMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/dashboards/token/[token].vue?macro=true";
import { default as tokenWOHGoSZRjmMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/dashboards/token.vue?macro=true";
import { default as demo6MJdAzAs2cMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/demo.vue?macro=true";
import { default as indexGHIstS2So8Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/events/index.vue?macro=true";
import { default as impressumEcSVqK4HTsMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/impressum.vue?macro=true";
import { default as indexBumzqf9kBIMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/index.vue?macro=true";
import { default as _91id_93F5osZGPT8iMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/deals/[id].vue?macro=true";
import { default as indexZswKbQZsTLMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/deals/index.vue?macro=true";
import { default as _91id_93eKKRKemPfBMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/devices/[id].vue?macro=true";
import { default as _91id_93nGfgEcriEYMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/devices/devicegroup/[id].vue?macro=true";
import { default as HSLtoRGB2pKErZ4ouiMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/devices/HSLtoRGB.js?macro=true";
import { default as indexarucEC5p23Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/devices/index.vue?macro=true";
import { default as RGBtoHSLSdaGBUbRD4Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/devices/RGBtoHSL.js?macro=true";
import { default as _91id_937mP6tFs4hoMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/eventTypes/[id].vue?macro=true";
import { default as indexT7QZRuIZcdMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/eventTypes/index.vue?macro=true";
import { default as _91id_93rcnollXKHgMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/goals/[id].vue?macro=true";
import { default as indexz6sLhQvWliMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/goals/index.vue?macro=true";
import { default as indexUatPr9cKN4Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/settings/index.vue?macro=true";
import { default as index5E5NoHT7V0Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/subscriptions/index.vue?macro=true";
import { default as _91id_93iPqOoNKRa4Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/users/[id].vue?macro=true";
import { default as indexeazDGQMUsjMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/users/index.vue?macro=true";
import { default as _91id_93x01qvNMWyRMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/sounds/[id].vue?macro=true";
import { default as entriesPOWwBksDZqMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/sounds/index/entries.vue?macro=true";
import { default as playlistsexK8OHc1a6Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/sounds/index/playlists.vue?macro=true";
import { default as indexORgzwsXDUWMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/sounds/index.vue?macro=true";
import { default as indexjAn0ApIgV8Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/devices/index.vue?macro=true";
import { default as _91id_936OfU9SVwOVMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/firmware/[id].vue?macro=true";
import { default as indexwew4Ktlui2Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/firmware/index.vue?macro=true";
import { default as _91id_934LsGK03eGWMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/hardware/[id].vue?macro=true";
import { default as indexuyIflPgbbuMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/hardware/index.vue?macro=true";
import { default as indexToDQMTKmn6Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/logs/index.vue?macro=true";
import { default as _91id_93Y8eFL3MK9VMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/notificationTemplates/[id].vue?macro=true";
import { default as indexarNCzgXVJqMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/notificationTemplates/index.vue?macro=true";
import { default as _91id_93jyARzZcNoRMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/organisations/[id].vue?macro=true";
import { default as indexgcKyDuoLeGMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/organisations/index.vue?macro=true";
import { default as _91id_93oMaISLH52OMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/plans/[id].vue?macro=true";
import { default as indexSxtZQkyxfsMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/plans/index.vue?macro=true";
import { default as releaseloglaVZr6G0onMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/releaselog.vue?macro=true";
import { default as toolsnAeRDJUvajMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/tools.vue?macro=true";
import { default as _91id_93QhoqeYscBlMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/users/[id].vue?macro=true";
import { default as indexT1deM0rjZHMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/users/index.vue?macro=true";
export default [
  {
    name: "auth-activate",
    path: "/auth/activate",
    meta: activateR5GLBF5yJeMeta || {},
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/auth/activate.vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: indexvuxJvdjoReMeta || {},
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/auth/index.vue")
  },
  {
    name: "auth-register-complete",
    path: "/auth/register-complete",
    meta: register_45completeGYztYoAaFsMeta || {},
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/auth/register-complete.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerNok2vMTxVvMeta || {},
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/auth/register.vue")
  },
  {
    name: "auth-restore",
    path: "/auth/restore",
    meta: restoresZSiS0LQKEMeta || {},
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/auth/restore.vue")
  },
  {
    name: "buzzer",
    path: "/buzzer",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/buzzer/index.vue")
  },
  {
    name: "dashboards-id",
    path: "/dashboards/:id()",
    meta: _91id_93VbZ1n3cva1Meta || {},
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/dashboards/[id].vue")
  },
  {
    name: "dashboards",
    path: "/dashboards",
    meta: indexeWMwo4uklHMeta || {},
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/dashboards/index.vue")
  },
  {
    name: "dashboards-token",
    path: "/dashboards/token",
    meta: tokenWOHGoSZRjmMeta || {},
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/dashboards/token.vue"),
    children: [
  {
    name: "dashboards-token-token",
    path: ":token()",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/dashboards/token/[token].vue")
  }
]
  },
  {
    name: "demo",
    path: "/demo",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/demo.vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/events/index.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/impressum.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/index.vue")
  },
  {
    name: "organisation-deals-id",
    path: "/organisation/deals/:id()",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/deals/[id].vue")
  },
  {
    name: "organisation-deals",
    path: "/organisation/deals",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/deals/index.vue")
  },
  {
    name: "organisation-devices-id",
    path: "/organisation/devices/:id()",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/devices/[id].vue")
  },
  {
    name: "organisation-devices-devicegroup-id",
    path: "/organisation/devices/devicegroup/:id()",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/devices/devicegroup/[id].vue")
  },
  {
    name: "organisation-devices-HSLtoRGB",
    path: "/organisation/devices/HSLtoRGB",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/devices/HSLtoRGB.js")
  },
  {
    name: "organisation-devices",
    path: "/organisation/devices",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/devices/index.vue")
  },
  {
    name: "organisation-devices-RGBtoHSL",
    path: "/organisation/devices/RGBtoHSL",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/devices/RGBtoHSL.js")
  },
  {
    name: "organisation-eventTypes-id",
    path: "/organisation/eventTypes/:id()",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/eventTypes/[id].vue")
  },
  {
    name: "organisation-eventTypes",
    path: "/organisation/eventTypes",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/eventTypes/index.vue")
  },
  {
    name: "organisation-goals-id",
    path: "/organisation/goals/:id()",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/goals/[id].vue")
  },
  {
    name: "organisation-goals",
    path: "/organisation/goals",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/goals/index.vue")
  },
  {
    name: "organisation-settings",
    path: "/organisation/settings",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/settings/index.vue")
  },
  {
    name: "organisation-subscriptions",
    path: "/organisation/subscriptions",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/subscriptions/index.vue")
  },
  {
    name: "organisation-users-id",
    path: "/organisation/users/:id()",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/users/[id].vue")
  },
  {
    name: "organisation-users",
    path: "/organisation/users",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/organisation/users/index.vue")
  },
  {
    name: "sounds-id",
    path: "/sounds/:id()",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/sounds/[id].vue")
  },
  {
    name: "sounds",
    path: "/sounds",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/sounds/index.vue"),
    children: [
  {
    name: "sounds-index-entries",
    path: "entries",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/sounds/index/entries.vue")
  },
  {
    name: "sounds-index-playlists",
    path: "playlists",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/sounds/index/playlists.vue")
  }
]
  },
  {
    name: "system-devices",
    path: "/system/devices",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/devices/index.vue")
  },
  {
    name: "system-firmware-id",
    path: "/system/firmware/:id()",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/firmware/[id].vue")
  },
  {
    name: "system-firmware",
    path: "/system/firmware",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/firmware/index.vue")
  },
  {
    name: "system-hardware-id",
    path: "/system/hardware/:id()",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/hardware/[id].vue")
  },
  {
    name: "system-hardware",
    path: "/system/hardware",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/hardware/index.vue")
  },
  {
    name: "system-logs",
    path: "/system/logs",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/logs/index.vue")
  },
  {
    name: "system-notificationTemplates-id",
    path: "/system/notificationTemplates/:id()",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/notificationTemplates/[id].vue")
  },
  {
    name: "system-notificationTemplates",
    path: "/system/notificationTemplates",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/notificationTemplates/index.vue")
  },
  {
    name: "system-organisations-id",
    path: "/system/organisations/:id()",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/organisations/[id].vue")
  },
  {
    name: "system-organisations",
    path: "/system/organisations",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/organisations/index.vue")
  },
  {
    name: "system-plans-id",
    path: "/system/plans/:id()",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/plans/[id].vue")
  },
  {
    name: "system-plans",
    path: "/system/plans",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/plans/index.vue")
  },
  {
    name: "system-releaselog",
    path: "/system/releaselog",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/releaselog.vue")
  },
  {
    name: "system-tools",
    path: "/system/tools",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/tools.vue")
  },
  {
    name: "system-users-id",
    path: "/system/users/:id()",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/users/[id].vue")
  },
  {
    name: "system-users",
    path: "/system/users",
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20250306112933/client/pages/system/users/index.vue")
  }
]